import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Layout } from '../components'
import { Fade } from '../utils'
import { Parallax } from 'react-scroll-parallax';

import { container, padding, bgImage, parallaxProps } from '../styles/global'
import { media } from '../styles/utils'
import { heading1, heading2, heading3, body, numbers } from '../styles/type'
import { brown1, brown3, brown4 } from '../styles/colors'

const OurStory = props => {
    const graphData = useStaticQuery(query).allWordpressInfopages.nodes[0]
    const data = JSON.parse(graphData.acf_json)
    console.log(data)

    const renderIntro = () => {
        return (
            <Intro>
                <Parallax
                    {...parallaxProps}
                >
                    <Image
                        key={data.hero_image.ID}
                        src={data.hero_image.sizes && data.hero_image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 1 : 1 }}
                                />
                            )
                        }}
                    </Image>
                </Parallax>

                <Fade delay={800}>
                    <Container>
                        <Grid>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.intro_heading,
                                }}
                            />
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.intro_text,
                                }}
                            />
                        </Grid>
                    </Container>
                </Fade>
            </Intro>
        )
    }

    const renderBelief = () => {
        const items = data.belief_list.map((item, i) => {
            return (
                <Belief key={i}>
                    <Fade delay={i*200}>
                        <Grid>
                            <Number>0{i + 1}</Number>

                            <Info>
                                <Heading
                                    dangerouslySetInnerHTML={{
                                        __html: item.heading,
                                    }}
                                />
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: item.text,
                                    }}
                                />
                            </Info>
                        </Grid>
                    </Fade>
                </Belief>
            )
        })

        return (
            <WhatWeBelieve>
                <Container>
                    <Heading
                        dangerouslySetInnerHTML={{
                            __html: data.belief_heading,
                        }}
                    />
                    <Beliefs>{items}</Beliefs>
                </Container>
            </WhatWeBelieve>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            desktopHeaderTheme={'dark'}
        >
            {renderIntro()}
            {renderBelief()}
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
`
const Grid = styled.div``

// Intro Section

const Logo = styled.img``
const Intro = styled.div`
    position: relative;
    height: 480px;
    padding-top: 160px;
    box-sizing: border-box;
    overflow: hidden;

    ${media.phone`
        min-height: initial;
        padding-top: 100px;
        padding-bottom: 64px;
        background-color: white;
    `}

    ${Grid} {
        display: flex;
        margin: 0 -20px;

        ${media.phone`
            display: block;
            margin: 0;
        `}
    }

    ${Heading}, ${Description} {
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box; 

        ${media.phone`
            width: 100%;
            padding: 0;
        `}
    }

    ${Heading} {
        ${heading1};
        transform: translateY(-0.2em);

        ${media.phone`
            transform: none;
        `}
    }

    ${Description} {
        ${media.phone`
            margin-top: 60px;
        `}

        * {
            ${heading2};
        }

        strong {
            font-weight: 600;
        }
    }

    ${Image},
    ${BGImage} {
        height: 100%;

        ${media.phone`
            display: none;
        `}
    }

    .parallax-outer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        
        ${media.phone`
            display: none;
        `}
    }

    ${BGImage} {
        min-height: 550px;
    }

    .react-reveal {
        height: 100%;
    }
`

// What we Believe Section
const Belief = styled.div``
const Number = styled.div``
const Beliefs = styled.div``
const WhatWeBelieve = styled.div`
    padding-top: 80px;
    padding-bottom: 160px;
    background-color: ${brown3};

    ${media.tabletLandscape`
        padding-top: 80px;
        padding-bottom: 128px;
    `}

    ${media.phone`
        background-color: ${brown4};
    `}

    ${Container} > ${Heading} {
        ${heading3};
    }

    ${Beliefs} {
        margin-top: 10px;
        border-top: 1px solid ${brown1};
        border-bottom: 1px solid ${brown1};

        ${media.phone`
            margin-top: 8px;
        `}
    }

    ${Belief} {
        padding: 42px 0 10px;

        ${media.tabletLandscape`
            padding-top: 50px;
            padding-bottom: 60px;
        `}

        ${media.phone`
            padding-top: 32px;
            padding-bottom: 40px;
        `}

        &:not(:last-child) {
            border-bottom: 1px solid white;
        }

        &:nth-child(even) {
            ${Number} {
                order: 99;
                text-align: right;
            }
        }
    }

    ${Grid} {
        display: flex;
        margin: 0 -20px;

        ${media.phone`
            margin: 0;
        `}
    }

    ${Number}, ${Info} {
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box;

        ${media.phone`
            padding: 0;
        `}
    }

    ${Number} {
        transform: translateY(-0.1em);
        ${numbers};
        color: white;

        ${media.tabletLandscape`
            transform: translateY(-0.08em);
        `}

        ${media.phone`
            transform: translateY(-0.1em);
        `}
    }

    ${Info} {
        ${Heading} {
            font-family: Chronicle Display;
            font-weight: 600;
            font-size: 30px;
            line-height: 1.167;

            ${media.phone`
                font-size: 19px;
                line-height: 1.25;
            `}
        }

        ${Description} {
            margin-top: 8px;
            ${body};
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "our-story" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default OurStory
